import React, { useEffect, useState } from 'react';
import { Card, Col, Container, FormLabel, Row } from 'react-bootstrap';
import axios from 'axios';

const News = () => {
    const [instaFeed, setInstaFeed] = useState([]);
    const [news, setNews] = useState([]);

    useEffect(() => {
        getInstaFeed();
        getNews();
    }, []);

    const getInstaFeed = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + '/instaFeed');
            setInstaFeed(response.data.data);
        } catch (error) {
            console.log(error);
        }
    }

    const getNews = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/news?limit=4`);
            setNews(response.data.result);
        } catch (error) {
            console.log(error);
        }
    }

    const mediaFeed = (feed) => {
        let post;
        let caption = feed.caption.split(' ');
        let stringCaption = '';

        if (caption.length >= 10) {
            for (let i = 0; i < 10; i++) {
                stringCaption += caption[i] + ' ';
            }

            stringCaption = stringCaption + ' ...';
        } else {
            stringCaption = feed.caption;
        }

        if (feed.media_type === 'VIDEO') {
            post = (
                <>
                    <Card className='h-100'>
                        <a href={feed.permalink} target='_blank' rel='noreferrer'>
                            <video width='100%' height='auto' src={feed.media_url} type="video/mp4"></video>
                        </a>
                        <Card.Body>
                            <Card.Title className='text-start'><a href={feed.permalink} target='_blank' rel='noreferrer' className='link'>{stringCaption}</a></Card.Title>
                        </Card.Body>
                    </Card>
                </>
            )
        }

        if (feed.media_type === 'CAROUSEL_ALBUM') {
            post = (
                <>
                    <Card className='h-100'>
                        <a href={feed.permalink} target='_blank' rel='noreferrer'>
                            <img width='100%' height='auto' id={feed.id} src={feed.media_url} alt={feed.caption} />
                        </a>
                        <Card.Body>
                            <Card.Title className='text-start'><a href={feed.permalink} target='_blank' rel='noreferrer' className='link'>{stringCaption}</a></Card.Title>
                        </Card.Body>
                    </Card>
                </>
            );
        }

        if (feed.media_type === 'IMAGE') {
            post = (
                <>
                    <Card className='h-100'>
                        <a href={feed.permalink} target='_blank' rel='noreferrer'>
                            <img width='100%' height='auto' id={feed.id} src={feed.media_url} alt={feed.caption} />
                        </a>
                        <Card.Body>
                            <Card.Title className='text-start'><a href={feed.permalink} target='_blank' rel='noreferrer' className='link'>{stringCaption}</a></Card.Title>
                        </Card.Body>
                    </Card>
                </>
            );
        }

        return post;
    }

    const convertDateTime = (dateTime) => {
        // convert date
        const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let date = new Date(dateTime);

        let d = date.getDate();
        let m = month[date.getMonth()];
        let y = date.getFullYear();
        let t = date.toTimeString().slice(0, 5);
        let convertDateTime = m + ' ' + d + ' , ' + y + ' | ' + t;

        return convertDateTime;
    }

    return (
        <div className="bg-body-tertiary pt-4" data-bs-theme="dark">
            <Container className='text-center'>
                <FormLabel className='fs-3 header-title'>NEWS</FormLabel>
                <Row xs={1} md={2} lg={4} className="g-4 news">
                    {news.map((news, index) => (
                        <Col key={index}>
                            <Card className='h-100'>
                                <a href={`/news/${news.url_subject}`}><Card.Img variant="top" src={process.env.REACT_APP_SITE_IMAGE + news.path + news.filename} /></a>
                                <Card.Body>
                                    <Card.Title className='text-start'><a href={`/news/${news.url_subject}`} className='link'>{news.subject}</a></Card.Title>
                                    <Card.Text className='text-start'>{convertDateTime(news.date_publish)}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
                <FormLabel className='fs-3 pt-4 header-title'>INSTAGRAM</FormLabel>
                <Row xs={1} md={2} lg={4} className="g-4 instagram">
                    {instaFeed.map((feed, index) => (
                        <Col key={index}>
                            {mediaFeed(feed)}
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
}

export default News;