import { BrowserRouter, Route, Routes } from "react-router-dom";
import ReactGA from "react-ga4";
import NavBar from "./components/Navbar";
import Home from "./components/Home";
import GameScheduleAll from "./components/GameScheduleAll";
import NewsAll from "./components/NewsAll";
import NewsDetail from "./components/NewsDetail";
import About from "./components/About";
import Partner from "./components/Partner";
import Player from "./components/Player";
import Team from "./components/Team";
import Gallery from "./components/Gallery";
import Footer from "./components/Footer";
import PlayerDetail from "./components/PlayerDetail";
import GalleryDetail from "./components/GalleryDetail";
import { Fragment } from "react";
import BackToTop from "./components/BackToTop";

ReactGA.initialize("G-WDE4PC7C4N");

function App() {
  return (
    <Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<><NavBar /><Home /></>}></Route>
          <Route path="/about" element={<><NavBar /><About /></>}></Route>
          <Route path="/gallery" element={<><NavBar /><Gallery /></>}></Route>
          <Route path="/gallery/:url_subject" element={<><NavBar /><GalleryDetail /></>}></Route>
          <Route path="/game-schedules" element={<><NavBar /><GameScheduleAll /></>}></Route>
          <Route path="/news" element={<><NavBar /><NewsAll /></>}></Route>
          <Route path="/news/:url_subject" element={<><NavBar /><NewsDetail /></>}></Route>
          <Route path="/roster" element={<><NavBar /><Player /></>}></Route>
          <Route path="/roster/:url_subject" element={<><NavBar /><PlayerDetail /></>}></Route>
          <Route path="/partner" element={<><NavBar /><Partner /></>}></Route>
          <Route path="/coaching-staff" element={<><NavBar /><Team /></>}></Route>
        </Routes>
      </BrowserRouter>
      <Footer />
      <BackToTop />
    </Fragment>
  );
}

export default App;
