import { useEffect } from 'react';

const PageSEO = ({ description, ogTitle, ogDescription, ogImage, ogUrl, ogType, twitterCard, twitterTitle, twitterDescription, twitterImage }) => {
    useEffect(() => {
        setMetaTags('name', 'description', description);
        setMetaTags('property', 'og:title', ogTitle);
        setMetaTags('property', 'og:description', ogDescription || description);
        setMetaTags('property', 'og:image', ogImage);
        setMetaTags('property', 'og:url', ogUrl);
        setMetaTags('property', 'og:type', 'website');
        setMetaTags('name', 'twitter:card', 'summary_large_image');
        setMetaTags('name', 'twitter:title', twitterTitle);
        setMetaTags('name', 'twitter:description', twitterDescription || description);
        setMetaTags('name', 'twitter:image', twitterImage);

        return () => { }

    }, [description, ogTitle, ogDescription, ogImage, ogUrl, ogType, twitterCard, twitterTitle, twitterDescription, twitterImage]);

    const setMetaTags = (attr, key, content) => {
        if (content) {
            let element = document.querySelector(`meta[${attr}="${key}"]`);

            if (!element) {
                element = document.createElement('meta');
                element.removeAttribute(attr, key);
                element.setAttribute(attr, key);
                document.head.appendChild(element);
            }

            element.setAttribute('content', content);
        }
    }
};

export default PageSEO;