import React from 'react';
import { Col, Container } from 'react-bootstrap';

const Footer = () => {
    return (
        <div data-bs-theme="dark" className='footer'>
            <Container className='pt-3 text-center'>
                <Col>
                    <span>&copy; Copyright </span>
                    <span className='name'>PT. Mega Bintang Persada </span>
                    <span>All Rights Reserved</span>
                </Col>
            </Container>
        </div>
    );
}
export default Footer;
