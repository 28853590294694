import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";

const BackToTop = () => {
    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true);
        } else if (scrolled <= 300) {
            setVisible(false);
        }
    };

    window.addEventListener("scroll", toggleVisible);

    const handleScrollToTop = (e) => {
        e.preventDefault();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return (
        <Button variant="" className="back-to-top" style={{ visibility: visible ? "visible" : "hidden", opacity: visible ? "1" : "0" }}>
            <FontAwesomeIcon icon={faArrowUp} onClick={(e) => handleScrollToTop(e)} />
        </Button>
    );
};

export default BackToTop;