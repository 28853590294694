import React, { useEffect, useState } from 'react';
import { Card, Col, Container, FormLabel, Row, Form } from 'react-bootstrap';
import { PaginationControl } from "react-bootstrap-pagination-control";
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceFrown } from '@fortawesome/free-solid-svg-icons';
import { useSearchParams } from "react-router-dom";
import ReactGA from "react-ga4";

ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: 'News',
});

const NewsAll = () => {
    const [news, setNews] = useState([]);

    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(0);
    const [keyword, setKeyword] = useState("");

    const [searchParams] = useSearchParams();

    useEffect(() => {
        getNews(page, keyword, searchParams);
    }, [page, keyword, searchParams]);

    const getNews = async (page, keyword, searchParams) => {
        try {
            let tagId = '';

            if (searchParams.get('tag')) {
                const resTag = await axios.get(process.env.REACT_APP_SITE + `/news_tag_all?tag_name=${searchParams.get('tag')}`);
                tagId = `&tags=${resTag.data[0]['id']}`;
            }

            const response = await axios.get(process.env.REACT_APP_SITE + `/news?search_query=${keyword}&page=${page}&limit=12${tagId}`);
            setNews(response.data.result);
            setPage(response.data.page);
            setRows(response.data.totalRows);
            window.scrollTo(0, 0);
        } catch (error) {
            console.log(error);
        }
    }

    const convertDateTime = (dateTime) => {
        // convert date
        const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let date = new Date(dateTime);

        let d = date.getDate();
        let m = month[date.getMonth()];
        let y = date.getFullYear();
        let t = date.toTimeString().slice(0, 5);
        let convertDateTime = m + ' ' + d + ' , ' + y + ' | ' + t;

        return convertDateTime;
    }

    const related = (news) => {
        let url = '/news/';

        if (JSON.parse(news.image_id).length > 0) {
            url = '/gallery/';
        }

        return (
            <>
                <a href={`${url + news.url_subject}`}><Card.Img variant="top" src={process.env.REACT_APP_SITE_IMAGE + news.path + news.filename} /></a>
                <Card.Body>
                    <Card.Title className='text-start'><a href={`${url + news.url_subject}`} className='link'>{news.subject}</a></Card.Title>
                    <Card.Text className='text-start'>{convertDateTime(news.date_publish)}</Card.Text>
                </Card.Body>
            </>
        )
    }

    return (
        <div className="bg-body-tertiary pb-5" id='container-page' data-bs-theme="dark">
            <Container className='text-center'>
                <FormLabel className='fs-3 header-title'>{searchParams.get('tag') || 'NEWS'}</FormLabel>
                {searchParams.get('tag') ?
                    ''
                    :
                    <Col lg={12} id='searchNews'>
                        <Row className='justify-content-end'>
                            <Col lg={3}>
                                <Form.Control type="text" value={keyword} onChange={(e) => setKeyword(e.target.value)} placeholder="Search..." />
                            </Col>
                        </Row>
                    </Col>
                }
                {news.length > 0 ?
                    <Row xs={1} sm={2} md={3} lg={4} className="g-4 pb-5 mt-3 news">
                        {news.map((news, index) => (
                            <Col key={index}>
                                <Card className='h-100'>
                                    {related(news)}
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    :
                    <FormLabel className='fs-3 text-center mt-5'><FontAwesomeIcon icon={faFaceFrown} /> NEWS NOT FOUND</FormLabel>
                }

                <PaginationControl page={page} between={4} total={rows} limit={12} changePage={(page) => { setPage(page) }} ellipsis={1} />
            </Container>
        </div>
    );
}

export default NewsAll;